.simonfo body {
  background-color: #fafafa;
  color: #1e1e1e;
}

.simonfo a {
  color: hsl(0, 15%, 45%);
}

.simonfo a:hover {
  color: hsl(0, 15%, 60%);
}
