body {
  margin: 5vh;
  font-family: -apple-system, 'Titillium Web Regular', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-variant: small-caps;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content-sections {
  display: flex;
  flex-direction: row;
}

@media (max-width: 700px) {
  .content-sections {
    flex-direction: column;
  }
}


/**
 * Base stylesheet for links.
 */
.external-link::after {
  font-family: 'Font Awesome 5 Free';
  content: '\f35d';
  font-weight: 900;
  padding-left: 5px;
  font-size: 0.75rem;
}


/**
 * Base stylesheet for lists.
 */
ul {
  list-style-type: square;
}
