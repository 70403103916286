.linked-resource {
  max-width: 40em;
}

.linked-resource-description {
  padding-left: 1em;
  margin-top: 0.5em;
  text-align: justify;
  font-size: 0.9rem;
}
