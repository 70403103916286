.topositus body {
  background-color: #fafafa;
  color: #222d3b;
}

.topositus a {
  color: #5c8dc9;
}
  
.topositus a:hover {
  color: #78bcdc;
}
