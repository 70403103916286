/* Extra Small Devices */
html {
  font-size: 16px;
}

/* Small Devices */
@media (min-width: 321px) and (max-width: 480px) {
  html {
      font-size: 16px;
  }
}

/* Medium Devices */
@media (min-width: 481px) and (max-width: 768px) {
  html {
      font-size: 18px;
  }
}

/* Large Devices */
@media (min-width: 769px) and (max-width: 1024px) {
  html {
      font-size: 18px;
  }
}

/* Extra Large Devices */
@media (min-width: 1025px) and (max-width: 1440px) {
  html {
      font-size: 18px;
  }
}

/* XXL Devices */
@media (min-width: 1441px) {
  html {
      font-size: 20px;
  }
}
