.teleport-home-button {
  background-color: hsl(0, 15%, 45%);
  border: none;
  border-radius: 8px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.teleport-home-button:hover {
  background-color: hsl(0, 15%, 60%);
}
